import "./App.scss";
import { useState } from "react";
import axios from "axios";
import logo from "./images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const uploadAvatar = async (files) => {
    try {
      setLoading(true);
      const file = files[0];
      let data = new FormData();
      data.append("file", file, file.name);
      let URL = `${process.env.REACT_APP_SERVER}/api/upload`;
      const upload = await axios.post(URL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setResponse(upload.data.response);
      setImage(upload.data.image);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };





  return (
    <div className="App">
      <img className="logo" src={logo} alt="logo" />
      <h2>AI Roast</h2>
      {!response && !loading && (
        <div className="fileUpload">
          <FontAwesomeIcon icon={faUpload} />
          <h3>Upload Your Image Here and Prepare to Get Roasted...</h3>
          <input
            onChange={(e) => {
              uploadAvatar(e.target.files);
            }}
            type="file"
          />
        </div>
      )}
      {loading && <p>Consulting our Roast Masters...</p>}

      {response && (
        <div className="divide">
          <div className="imageContainer">
            <img src={image} alt="roast" />
          </div>
          <div className="response">
            <p>{response}</p>
          </div>
        </div>
      )}
      {response && (
        <>
          <button
            onClick={() => {
              setResponse(false);
              setLoading(false);
            }}
          >
            Try Again
          </button>
        </>
      )}

      <div className="footer">
        <p>
          Made with <span>❤️</span> by{" "}
          <a href="https://www.linkedin.com/in/jakeboyles/">
            Jake Boyles
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
